const ClassroomStatuses = {
  pending: {
    value: 'pending',
    info: 'pending_info',
  },
  on_going: {
    value: 'on_going',
    info: 'on_going_info',
  },
  scheduled: {
    value: 'scheduled',
    info: 'scheduled_info',
  },
  ready_to_go: {
    value: 'ready_to_go',
    info: 'ready_to_go_info',
  },
  closed: {
    value: 'closed',
    info: 'closed_info',
  },
  interrupted: {
    value: 'interrupted',
    info: 'interrupted_info',
  },
}

export default ClassroomStatuses
