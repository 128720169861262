import { render, staticRenderFns } from "./ClassroomOnGoingCertificatesRecap.vue?vue&type=template&id=0c513e18&scoped=true&"
import script from "./ClassroomOnGoingCertificatesRecap.vue?vue&type=script&lang=js&"
export * from "./ClassroomOnGoingCertificatesRecap.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c513e18",
  null
  
)

export default component.exports