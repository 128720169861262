<template>
  <div>
    <octo-header-button
      :title="classroom.code"
      :buttons="headerButtons"
      @onSaveCertificateLesson="saveCertificateLesson"
    />
    <card body-classes="standard-card-body" class="mr-2 shadow">
      <div slot="header">
        <div class="d-flex align-items-center">
          <h4 class="card-title text-capitalize">{{ $t('didactics.location_data') }}</h4>
        </div>
      </div>
      <certificate-lesson-form :lesson="localLesson" ref="lessonForm" :key="`form-${lessonKey}`"/>
    </card>
    <card body-classes="standard-card-body" class="mr-2 shadow">
      <div slot="header">
        <div class="d-flex align-items-center">
          <h4 class="card-title text-capitalize">{{ $t('fields.location') }}</h4>
        </div>
      </div>
      <list-selector-component exclusive :list="localLocations" ref="listSelectorComponent">
        <template v-slot:default="slotProps">
          {{ slotProps.item.alias }}
        </template>
      </list-selector-component>
    </card>
  </div>
</template>

<script>

import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {mapGetters, mapMutations} from "vuex";
import Lesson from "@/models/lesson";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import {endpoints} from "@/routes/endpoints";
import CertificateLessonForm from "@/pages/Didactics/forms/CertificateLessonForm";

export default {
  name: "ClassroomOnGoingCertificationData",
  components: {CertificateLessonForm, ListSelectorComponent, OctoHeaderButton},
  data() {
    return {
      headerButtons: [
        {
          label: 'save_location',
          onClick: 'onSaveCertificateLesson'
        },
      ],
      localLesson: this.$_.cloneDeep(Lesson),
      localLocations: [],
      lessonKey: 0
    }
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing',
      locations: 'common/locations'
    }),
  },
  watch: {
    classroom: {
      handler(val) {
        this.localLesson = this.$_.cloneDeep(this.classroom?.lessons?.[0] || Lesson);
        this.localLocations = this.$_.map(this.$_.cloneDeep(this.locations), location => location);
        this.lessonKey++;
        this.$refs.listSelectorComponent.itemSelected = this.localLesson.location_id;
      },
      deep: true
    },
  },
  methods: {
    ...mapMutations({
      setPartialClassroomOnGoing: 'current/setPartialClassroomOnGoing'
    }),

    saveCertificateLesson: async function () {
      try {
        const lessonData = await this.$refs.lessonForm.validate();
        lessonData.location_id = this.$refs.listSelectorComponent.getSelectedItem() || null;

        this.$fullLoading.show();

        const resp = await this.$api.put(
          endpoints.CLASSROOM_UPDATE_PENDING_CERTIFICATE_LESSON.replace('{id}', this.classroom.id),
          lessonData
        );

        this.setPartialClassroomOnGoing({key: 'lessons', value: resp?.data?.data?.lessons});

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        console.error(e);
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },
  },
}
</script>

<style scoped>

</style>
