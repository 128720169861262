<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <tabs centered square>
          <tab-pane :id="1">
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.summary') }}</div>
              </div>
            </template>
            <classroom-on-going-recap/>
            <div class="container-fluid">
              <classroom-on-going-students-by-certificate-recap/>
              <classroom-on-going-certificates-recap/>
              <div class="row mt-2">
                <div class="col-12">
                  <octo-notes
                    entity="classroom"
                    :entity-id="classroom.id"
                    :key="`octo-notes-${classroomLoadedKey}`"
                    :notes="classroom.notes"
                  />
                </div>
              </div>
            </div>
          </tab-pane>

          <tab-pane :id="2">
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('fields.location') }}</div>
              </div>
            </template>
            <classroom-on-going-certification-data/>
          </tab-pane>

          <tab-pane :id="3" v-if="classroom.status !== classroomStatuses.closed.value">
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('didactics.certificate_result') }}</div>
              </div>
            </template>
            <classroom-on-going-students-by-certificate-presences/>
          </tab-pane>

          <tab-pane :id="4" v-if="classroom.status !== classroomStatuses.closed.value">
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('didactics.students') }}</div>
              </div>
            </template>
            <classroom-on-going-students/>
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>

import {TabPane, Tabs} from "@/components";
import {endpoints} from "@/routes/endpoints";
import {mapMutations} from "vuex";
import ClassroomOnGoing from "@/store/current/resources/classroomOnGoing";
import OctoNotes from "@/components/OctoNotes";
import classroomTypes from "@/pages/Didactics/resources/classroomTypes";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import ClassroomOnGoingStudentsByCertificatePresences
  from "@/pages/Didactics/components/ClassroomOnGoingStudentsByCertificatePresences";
import ClassroomOnGoingCertificationData from "@/pages/Didactics/components/ClassroomOnGoingCertificationData";
import ClassroomOnGoingStudents from "./components/ClassroomOnGoingStudents";
import ClassroomOnGoingRecap from "./components/ClassroomOnGoingRecap";
import classroomStatuses from "./resources/classroomStatuses";
import ClassroomOnGoingStudentsByCertificateRecap from "./components/ClassroomOnGoingStudentsByCertificateRecap";
import ClassroomOnGoingCertificatesRecap from "./components/ClassroomOnGoingCertificatesRecap";
import ShowClassroomArchiveCertificatePageHelper from "@/components/Helpers/ShowClassroomArchiveCertificatePageHelper";

export default {
  name: "ShowClassroomArchiveCertificatePage",
  components: {
    ClassroomOnGoingCertificatesRecap,
    ClassroomOnGoingStudentsByCertificateRecap,
    ClassroomOnGoingRecap,
    ClassroomOnGoingStudents,
    ClassroomOnGoingCertificationData,
    ClassroomOnGoingStudentsByCertificatePresences,
    OctoHeaderButton,
    OctoNotes,
    Tabs,
    TabPane
  },
  data() {
    return {
      classroom: this.$_.cloneDeep(ClassroomOnGoing),
      classroomLoadedKey: 1,
      classroomTypes: classroomTypes,
      classroomStatuses: classroomStatuses,
      helperComponent: ShowClassroomArchiveCertificatePageHelper
    }
  },
  beforeMount() {
    this.$fullLoading.show();
    this.classroom = this.$_.cloneDeep(ClassroomOnGoing);
    this.setClassroomOnGoing(this.classroom);
    this.$api.get(endpoints.CLASSROOM_SHOW_ON_GOING.replace('{id}', this.$route.params.id))
      .then((resp) => {
        this.classroom = {...this.classroom, ...resp?.data?.data}
        this.classroom.availableTutors = resp?.data?.meta?.availableTutors || [];
        this.classroom.availableStudents = resp?.data?.meta?.availableStudents || [];
        this.classroom.students = resp?.data?.meta?.students || [];
        this.$nextTick(() => {
          this.setClassroomOnGoing(this.classroom);
          this.classroomLoadedKey++;
        });
      })
      .catch((e) => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        this.$router.push({name: 'didactics.classrooms.index'})
      })
      .finally(() => {
        this.$fullLoading.hide();
      });
  },
  methods: {
    ...mapMutations({
      setClassroomOnGoing: 'current/setClassroomOnGoing',
      setHelper: 'common/setHelper',
    })
  },
  mounted() {
    this.setHelper(this.helperComponent);
  },
  beforeDestroy() {
    this.setHelper();
  }
}
</script>

<style scoped>

</style>
