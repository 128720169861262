var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('octo-header-button',{attrs:{"buttons":_vm.headerButtons,"title":_vm.classroom.code},on:{"onUpdateCertificatePresences":_vm.updateCertificatePresences}})],1),_c('div',{staticClass:"col-12"},[_c('card',{staticClass:"shadow",attrs:{"body-classes":"standard-card-body"}},[_c('el-table',{attrs:{"stripe":"","data":_vm.students}},[_c('el-table-column',{attrs:{"align":"left","min-width":"250","label":_vm.$t('didactics.student')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm._f("optional")(row,'registry.surname'))+" "+_vm._s(_vm._f("optional")(row,'registry.name'))+" ")])])}}])}),_c('el-table-column',{attrs:{"align":"left","min-width":"250","label":_vm.$t('common.certificate')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-tooltip',{attrs:{"content":_vm._f("optional")(_vm.courses[row.course_id],'title'),"open-delay":300,"placement":"top"}},[_c('label-theme-component',[_vm._v(_vm._s(_vm._f("optional")(_vm.courses[row.course_id],'code')))])],1)],1)}}])}),_c('el-table-column',{attrs:{"align":"center","width":"100","label":_vm.$t('didactics.present')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-switch',{on:{"change":function($event){return _vm.togglePromoted(row)}},model:{value:(row.lessonPresence),callback:function ($$v) {_vm.$set(row, "lessonPresence", $$v)},expression:"row.lessonPresence"}})],1)}}])}),_c('el-table-column',{attrs:{"align":"center","width":"100","label":_vm.$t('didactics.promoted')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-switch',{attrs:{"disabled":!row.lessonPresence},model:{value:(row.promoted),callback:function ($$v) {_vm.$set(row, "promoted", $$v)},expression:"row.promoted"}})],1)}}])}),_c('template',{slot:"empty"},[_vm._v(" "+_vm._s(_vm.$t('datatable.no_result'))+" ")])],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }