var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('octo-header-button',{attrs:{"title":_vm.classroom.code,"buttons":_vm.headerButtons},on:{"onSaveStudents":_vm.saveStudents}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',{staticClass:"mr-2 shadow mb-3",attrs:{"body-classes":"standard-card-body"}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"card-title text-capitalize"},[_vm._v(_vm._s(_vm.$t('didactics.joined_students')))])]),_c('el-table',{attrs:{"width":"100","stripe":"","data":_vm.studentsSelected}},[_c('el-table-column',{attrs:{"min-width":200,"align":"left","label":_vm.$t('datatable.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-truncate small text-uppercase"},[_vm._v(" "+_vm._s(_vm._f("optional")(row,'registry.surname'))+" "+_vm._s(_vm._f("optional")(row,'registry.name'))+" ")]),_c('div',{staticClass:"small text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t('datatable.date_in'))+": "),_c('label-theme-component',[_vm._v(_vm._s(_vm._f("date")(row.date_in)))])],1)])}}])}),_c('el-table-column',{attrs:{"width":120,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-nowrap small"},[_vm._v(" "+_vm._s(_vm._f("optional")(_vm.courses[row.course_id],'code'))+" ")])])}}])}),_c('el-table-column',{attrs:{"width":150,"align":"center","label":_vm.$t('datatable.first_contact')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-truncate small"},[_vm._v(" "+_vm._s(_vm._f("date")(row.first_contact))+" ")])])}}])}),_c('el-table-column',{attrs:{"align":"center","width":360,"label":_vm.$t('datatable.schedule_preferences')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('student-schedule-preferences',{attrs:{"preferences":row.studentSchedulePreferences}})],1)}}])}),_c('el-table-column',{attrs:{"width":160,"align":"center","label":_vm.$t('datatable.second_contact')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-truncate small"},[_vm._v(" "+_vm._s(_vm._f("date")(row.second_contact))+" ")])])}}])}),(_vm.showAccountingApproval)?_c('el-table-column',{attrs:{"width":200,"align":"center","label":_vm.$t('common.accounting_approval')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('octo-icon',{attrs:{"icon":row.accounting_approval ? 'checked' : 'wrong'}})],1)}}],null,false,1671092116)}):_vm._e(),(_vm.showAccountingApproval)?_c('el-table-column',{attrs:{"width":200,"align":"center","label":_vm.$t('common.enabled_certifications')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm.getEnabledCertificationsCodes(row))+" ")])}}],null,false,1645585494)}):_vm._e(),_c('el-table-column',{attrs:{"width":100,"align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return _c('div',{},[_c('base-button',{staticClass:"ml-3 mb-2",attrs:{"icon":"","link":""},on:{"click":function($event){return _vm.toggleStudent(row)}}},[_c('octo-icon',{attrs:{"icon":"wrong"}})],1)],1)}}])})],1)],2)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',{staticClass:"mr-2 shadow",attrs:{"body-classes":"standard-card-body"}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"card-title text-capitalize"},[_vm._v(_vm._s(_vm.$t('didactics.available_students')))])]),_c('div',{staticClass:"text-uppercase small d-flex align-items-center justify-content-center mb-3"},[_c('el-switch',{staticClass:"mr-2",model:{value:(_vm.showStudentsWithScheduleOnly),callback:function ($$v) {_vm.showStudentsWithScheduleOnly=$$v},expression:"showStudentsWithScheduleOnly"}}),_c('label-theme-component',[_vm._v(_vm._s(_vm.$t('didactics.show_students_with_schedule_only')))])],1),_c('el-table',{attrs:{"width":"100","stripe":"","data":_vm.studentsAvailable}},[_c('el-table-column',{attrs:{"min-width":200,"align":"left","label":_vm.$t('datatable.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-truncate small text-uppercase"},[_vm._v(" "+_vm._s(_vm._f("optional")(row,'registry.surname'))+" "+_vm._s(_vm._f("optional")(row,'registry.name'))+" ")]),_c('div',{staticClass:"small text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t('datatable.date_in'))+": "),_c('label-theme-component',[_vm._v(_vm._s(_vm._f("date")(row.date_in)))])],1)])}}])}),_c('el-table-column',{attrs:{"width":120,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-nowrap small"},[_vm._v(" "+_vm._s(_vm._f("optional")(_vm.courses[row.course_id],'code'))+" ")])])}}])}),_c('el-table-column',{attrs:{"width":150,"align":"center","label":_vm.$t('datatable.first_contact')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-truncate small"},[_vm._v(" "+_vm._s(_vm._f("date")(row.first_contact))+" ")])])}}])}),_c('el-table-column',{attrs:{"align":"center","width":360,"label":_vm.$t('datatable.schedule_preferences')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('student-schedule-preferences',{attrs:{"preferences":row.studentSchedulePreferences}})],1)}}])}),_c('el-table-column',{attrs:{"width":160,"align":"center","label":_vm.$t('datatable.second_contact')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-truncate small"},[_vm._v(" "+_vm._s(_vm._f("date")(row.second_contact))+" ")])])}}])}),(_vm.showAccountingApproval)?_c('el-table-column',{attrs:{"width":200,"align":"center","label":_vm.$t('common.accounting_approval')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('octo-icon',{attrs:{"icon":row.accounting_approval ? 'checked' : 'wrong'}})],1)}}],null,false,1671092116)}):_vm._e(),(_vm.showAccountingApproval)?_c('el-table-column',{attrs:{"width":200,"align":"center","label":_vm.$t('common.enabled_certifications')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},_vm._l((row.registry.enabled_certifications),function(cert,index){return (row.registry && row.registry.enabled_certifications)?_c('div',{key:("cert-" + index)},[_c('div',{staticClass:"small"},[_vm._v(_vm._s(_vm._f("optional")(_vm.courses[cert.id],'code')))])]):_vm._e()}),0)}}],null,false,4218306719)}):_vm._e(),_c('el-table-column',{attrs:{"width":100,"align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return _c('div',{},[_c('base-button',{staticClass:"ml-3 mb-2",attrs:{"icon":"","link":""},on:{"click":function($event){return _vm.toggleStudent(row)}}},[_c('octo-icon',{attrs:{"icon":"add"}})],1)],1)}}])})],1)],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }