<template>
  <div>
    <p class="text-uppercase">{{ $t('common.summary') }}</p>
    <div class="ml-2 text-muted">
      Riepilogo della situazione generale dell'aula. Sono presenti i dati dell'aula, gli esiti e presenze degli
      studenti, l'elenco dei certificati e la gestione delle note.
    </div>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('common.save')">
        <div>
          Salva dati generali dell'aula.
        </div>
      </collapse-item>
      <collapse-item
        :title="$t('common.close_classroom')"
        v-if="classroom.status === classroomStatuses.on_going.value"
      >
        <div>
          Imposta l'aula nello stato {{ $t('didactics.classroom_status.closed') }} e gli studenti nello stato
          {{ $t('didactics.student_status.completed') }}.
          Dopo non sarà possibile aggiornare gli studenti, esiti e presenze a meno che l'aula non venga riattivata.
        </div>
      </collapse-item>
      <collapse-item
        :title="$t('common.reactivate_classroom')"
        v-if="classroom.status === classroomStatuses.closed.value"
      >
        <div>
          Imposta l'aula nello stato {{ $t('didactics.classroom_status.on_going') }} e gli studenti di nuovo
          come frequentanti.
        </div>
      </collapse-item>
    </collapse>
    <p class="text-uppercase mt-2">{{ $t('common.location') }}</p>
    <div class="ml-2 text-muted">
      Dati della sede dove verrà svolto l'esame di certificazione.
    </div>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('common.save_location')">
        <div>
          Salva i dettagli dell'incontro.
        </div>
      </collapse-item>
    </collapse>
    <div v-if="classroom.status === classroomStatuses.on_going.value">
      <p class="text-uppercase mt-2">{{ $t('didactics.certificate_result') }}</p>
      <div class="ml-2 text-muted">
        Elenco degli studenti dov'è possibile definire l'esito e la presenza di ogni studente all'esame.
      </div>
      <collapse :multiple-active="false">
        <collapse-item :title="$t('common.save_certificate_result')">
          <div>
            Salva gli esiti e le presenze dell'elenco degli studenti.
          </div>
        </collapse-item>
      </collapse>
      <p class="text-uppercase mt-2">{{ $t('didactics.students') }}</p>
      <div class="ml-2 text-muted">
        Elenco degli studenti iscritti e disponibili. È possibile aggiungere e togliere studenti anche se sono stati
        salvati gli esiti e le presenze.
      </div>
      <collapse :multiple-active="false">
        <collapse-item :title="$t('common.save_students')">
          <div>
            Aggiorna gli studenti iscritti all'aula.
          </div>
        </collapse-item>
      </collapse>
    </div>
  </div>
</template>

<script>
import CollapseItem from "@/components/Collapse/CollapseItem";
import Collapse from "@/components/Collapse/Collapse";
import classroomStatuses from "@/pages/Didactics/resources/classroomStatuses";
import {mapGetters} from "vuex";

export default {
  name: "ShowClassroomArchiveCertificatePageHelper",
  components: {CollapseItem, Collapse},
  data() {
    return {
      classroomStatuses: classroomStatuses
    }
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing'
    }),
  }
}
</script>

<style scoped>

</style>
