<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <octo-header-button
          :title="classroom.code"
          :buttons="headerButtons"
          @onSaveClassroom="saveClassroom"
          @onExportStudents="exportStudents"
          @onCloseClassroom="closeClassroom"
          @onReactivateClassroom="reactivateClassroom"
          @onInterruptClassroom="interruptClassroom"
          @onReactivateInterruptedClassroom="reactivateInterruptedClassroom"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <card body-classes="standard-card-body" class="shadow mb-0">
          <ul class="list-group list-group-flush">
            <list-group-item-component :label="$t('fields.code')" :label-width="labelWidth">
              <template slot="value">
                <label-theme-component v-if="isTutor">
                  {{ classroom.code }}
                </label-theme-component>
                <base-input
                  v-else
                  class="flex-grow-1 mb-0"
                  v-model="localClassroom.code"
                  type="text"
                />
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.schedulation')" :label-width="labelWidth">
              <template slot="value">
                <label-theme-component v-if="isTutor">
                  {{ classroom.schedulation }}
                </label-theme-component>
                <base-input
                  v-else
                  class="flex-grow-1 mb-0"
                  v-model="localClassroom.schedulation"
                  type="text"
                  :placeholder="$t('fields.schedulation')"
                />
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.expected_start_date')" :label-width="labelWidth">
              <template slot="value">
                <label-theme-component v-if="isTutor">
                  {{ classroom.expected_start_date | date }}
                </label-theme-component>
                <base-input v-else class="flex-grow-1 mb-0">
                  <el-date-picker
                    type="date"
                    v-model="localClassroom.expected_start_date"
                    value-format="yyyy-MM-dd"
                    format="dd/MM/yyyy"
                    :placeholder="$t('common.select_day')"
                    :picker-options="{firstDayOfWeek: 1}"
                  />
                </base-input>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.certificationBody')" :label-width="labelWidth">
              <template slot="value">
                <label-theme-component v-if="isTutor">
                  {{ classroom.certification_body }}
                </label-theme-component>
                <base-input
                  v-else
                  class="flex-grow-1 mb-0"
                  v-model="localClassroom.certification_body"
                  type="text"
                  :placeholder="$t('fields.certificationBody')"
                />
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.location')" :label-width="labelWidth">
              <template slot="value">
                <base-input class="mb-0 flex-grow-1">
                  <el-select
                    class="select-default text-uppercase"
                    filterable
                    clearable
                    value-key="id"
                    :placeholder="$t('common.select')"
                    v-model="localClassroom.location_id"
                  >
                    <el-option
                      class="select-default text-uppercase"
                      v-for="(location, index) in locations"
                      :value="location.id"
                      :label="location.alias"
                      :key="`location-${index}`"
                    />
                  </el-select>
                </base-input>
              </template>
            </list-group-item-component>
          </ul>
        </card>
      </div>
      <div class="col-md-6">
        <classroom-status-container :classroom="classroom"/>
        <card class="shadow" body-classes="standard-card-body pt-0">
          <ul class="list-group list-group-flush">
            <list-group-item-component
              :label="$t('didactics.joined_students')"
              :value="localClassroom.students.length"
            />
            <list-group-item-component
              :label="$t('didactics.available_students')"
              :value="localClassroom.availableStudents.length"
            />
            <list-group-item-component
              :label="$t('didactics.tutors_number')"
              :value="localClassroom.tutors.length"
            />
          </ul>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {mapActions, mapGetters, mapMutations} from "vuex";
import ClassroomOnGoing from "@/store/current/resources/classroomOnGoing";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {DatePicker, Select, Option} from "element-ui";
import ClassroomStatusContainer from "@/pages/Didactics/components/ClassroomStatusContainer";
import {endpoints} from "@/routes/endpoints";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import classroomTypes from "@/pages/Didactics/resources/classroomTypes";
import classroomStatuses from "@/pages/Didactics/resources/classroomStatuses";
import {permissionService} from "@/util/permission-service";

export default {
  name: "ClassroomOnGoingRecap",
  components: {
    LabelThemeComponent,
    ClassroomStatusContainer,
    ListGroupItemComponent,
    OctoHeaderButton,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      labelWidth: 160,
      localClassroom: this.$_.cloneDeep(ClassroomOnGoing),
      endpoints: endpoints,
      classroomTypes: classroomTypes
    }
  },
  watch: {
    classroom: {
      handler(val) {
        this.localClassroom = this.$_.cloneDeep(this.classroom);
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing',
      locations: 'common/locations',
    }),

    headerButtons() {
      if (this.isTutor) {
        return this.classroom.status === classroomStatuses.closed.value
          ? []
          : [{
            label: 'close_classroom',
            onClick: 'onCloseClassroom',
            confirmRequired: true,
            confirmText: this.$t('confirm.close_classroom')
          }];
      }

      const buttons = [
        {label: 'save', onClick: 'onSaveClassroom'},
        {label: 'export_students', onClick: 'onExportStudents'},
      ];

      if (this.classroom.status === classroomStatuses.closed.value) {
        buttons.push({label: 'reactivate_classroom', onClick: 'onReactivateClassroom'});
      }

      if (this.classroom.status === classroomStatuses.interrupted.value) {
        buttons.push({label: 'reactivate_classroom', onClick: 'onReactivateInterruptedClassroom'});
      }

      if (
        this.classroom.status !== classroomStatuses.closed.value
        && this.classroom.status !== classroomStatuses.interrupted.value
      ) {
        buttons.push({
          label: 'close_classroom',
          onClick: 'onCloseClassroom',
          confirmRequired: true,
          confirmText: this.$t('confirm.close_classroom')
        });
        buttons.push({
          label: 'interrupt_classroom',
          onClick: 'onInterruptClassroom',
          confirmRequired: true,
          confirmText: this.$t('confirm.interrupt_classroom')
        });
      }

      return buttons;
    },

    isTutor() {
      return !permissionService.isAllowed([permissionService.TEACHING_MANAGER_ROLE]);
    }
  },
  methods: {
    ...mapMutations({
      setPartialClassroomOnGoing: 'current/setPartialClassroomOnGoing',
    }),

    ...mapActions({
      downloadFile: 'common/downloadFile'
    }),

    saveClassroom: function () {
      this.$fullLoading.show();

      const data = {
        code: this.localClassroom.code,
        schedulation: this.localClassroom.schedulation,
        expected_start_date: this.localClassroom.expected_start_date,
        certification_body: this.localClassroom.certification_body,
        location_id: this.localClassroom.location_id
      };

      this.$api.put(endpoints.CLASSROOM_UPDATE_PENDING.replace('{id}', this.classroom.id), data)
        .then((resp) => {
          this.$_.each(data, (value, key) => {
            this.setPartialClassroomOnGoing({key: key, value: resp?.data?.data?.[key]})
          });
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch((e) => {
          console.log(e);
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    closeClassroom: function () {
      this.$fullLoading.show();

      this.$api.put(endpoints.CLASSROOM_CLOSE_ON_GOING.replace('{id}', this.classroom.id))
        .then((resp) => {
          this.setPartialClassroomOnGoing({key: 'students', value: resp?.data?.data?.students});
          this.setPartialClassroomOnGoing({key: 'lessons', value: resp?.data?.data?.lessons});
          this.setPartialClassroomOnGoing({key: 'status', value: resp?.data?.data?.status});
        })
        .catch((e) => {
          console.log(e);
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    reactivateClassroom: function () {
      this.$fullLoading.show();

      this.$api.put(endpoints.CLASSROOM_REACTIVATE_CLOSED.replace('{id}', this.classroom.id))
        .then((resp) => {
          this.setPartialClassroomOnGoing({key: 'status', value: resp?.data?.data?.status});
          this.setPartialClassroomOnGoing({key: 'students', value: resp?.data?.data?.students});
          this.$nextTick(() => {
            this.setPartialClassroomOnGoing({key: 'tutors', value: resp?.data?.data?.tutors});
          });
        })
        .catch((e) => {
          console.log(e);
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    interruptClassroom: function () {
      this.$fullLoading.show();

      this.$api.put(endpoints.CLASSROOM_INTERRUPT_ON_GOING.replace('{id}', this.classroom.id))
        .then((resp) => {
          this.setPartialClassroomOnGoing({key: 'status', value: resp?.data?.data?.status});
        })
        .catch((e) => {
          console.log(e);
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    reactivateInterruptedClassroom: function () {
      this.$fullLoading.show();

      this.$api.put(endpoints.CLASSROOM_REACTIVATE_INTERRUPTED.replace('{id}', this.classroom.id))
        .then((resp) => {
          this.setPartialClassroomOnGoing({key: 'status', value: resp?.data?.data?.status});
        })
        .catch((e) => {
          console.log(e);
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    exportStudents: function () {
      this.$fullLoading.show();

      this.downloadFile({
        url: endpoints.CLASSROOM_EXPORT_STUDENTS.replace('{id}', this.classroom.id),
        filename: 'classroom-' + this.classroom.code + '-students-export.xlsx',
        method: 'get',
      })
        .then()
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.export_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        });
    },
  }
}
</script>

<style scoped>

</style>
