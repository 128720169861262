const ClassroomTypes = {
  course: {
    value: 'course',
  },
  certificate: {
    value: 'certificate',
  },
  workshop: {
    value: 'workshop',
  },

}

export default ClassroomTypes
