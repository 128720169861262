<template>
  <div class="d-flex containerPreferences">
    <div
        v-for="(item, index) in daysOfWeek" v-bind:key="`day-${index}`"
        class="px-1"
        :class="index !== '1' ? 'border-left' : ''"
    >
      <div class=" rowPref small text-uppercase text-center">{{ item.day }}</div>
      <div class="d-flex justify-content-between">
        <octo-icon :class="item.morning ? 'text-primary' : 'text-muted transparent'"
                   class="iconPreferences"
                   icon="morning"
        />
        <octo-icon :class="item.afternoon ? 'text-warning' : 'text-muted transparent'"
                   class="iconPreferences"
                   icon="sun"
        />
      </div>
      <div class="d-flex justify-content-between">
        <octo-icon :class="item.late_afternoon ? 'text-info' : 'text-muted transparent'"
                   class="iconPreferences"
                   icon="sun"
        />
        <octo-icon :class="item.evening ? 'text-info' : 'text-muted transparent'"
                   class="iconPreferences"
                   icon="moon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {Card} from "element-ui";

export default {
  name: "StudentSchedulePreferences",
  components: {
    OctoIcon,
    [Card.name]: Card,
  },
  data() {
    return {}
  },
  props: {
    preferences: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    daysOfWeek() {
      const days = {
        1: {day: 'Lun', morning: false, afternoon: false, late_afternoon :false, evening: false},
        2: {day: 'Mar', morning: false, afternoon: false, late_afternoon :false, evening: false},
        3: {day: 'Mer', morning: false, afternoon: false, late_afternoon :false, evening: false},
        4: {day: 'Gio', morning: false, afternoon: false, late_afternoon :false, evening: false},
        5: {day: 'Ven', morning: false, afternoon: false, late_afternoon :false, evening: false},
        6: {day: 'Sab', morning: false, afternoon: false, late_afternoon :false, evening: false},
        7: {day: 'Dom', morning: false, afternoon: false, late_afternoon :false, evening: false},
      }

      if(this.preferences.length) {
        this.$_.each(this.preferences, (item) => {
          if(days.hasOwnProperty(item.day_of_week)) {
            days[item.day_of_week].morning = item.morning;
            days[item.day_of_week].afternoon = item.afternoon;
            days[item.day_of_week].late_afternoon = item.late_afternoon;
            days[item.day_of_week].evening = item.evening;
          }
        })
      }


      return days;
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.iconPreferences {
  font-size: 16px;
}

.rowPref {
  width: 40px;
}

.containerPreferences {
  width: 338px;
}

.transparent {
  opacity: 25%;
}
</style>
