import Classroom from "@/models/classroom";

const Lesson = {
  id: null,
  tutor_id: null,
  classroom_id: null,
  location_id: null,
  code: '',
  date: '',
  time_start: '',
  time_end: '',
  status: null,
  type: null,
  order: null,
  file_type: '',
  size: null,
  downloaded: false,
  uploaded: false,
  sent: false,
  mp4: false,
  classroom: _.cloneDeep(Classroom)
};

export default Lesson
