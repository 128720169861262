<template>
    <base-alert :type="alertType" class="py-4">
      <div class="text-white text-center text-uppercase" v-if="classroom.status">
        {{ $t('didactics.classroom_status.' + classroom.status) }}
      </div>
    </base-alert>
</template>

<script>

import BaseAlert from "@/components/BaseAlert";
import Classroom from "@/models/classroom";

export default {
  name: "ClassroomStatusContainer",
  components: {BaseAlert},
  data() {
    return {
    }
  },
  props: {
    classroom: {
      type: Object,
      default: () => this.$_.cloneDeep(Classroom)
    },
  },
  computed: {
    alertType: function () {
      return this.classroom.status ? this.$options.filters.classroomStatusColor(this.classroom.status) : 'info';
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
