<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <octo-header-button :title="classroom.code" :buttons="headerButtons" @onSaveStudents="saveStudents"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card body-classes="standard-card-body" class="mr-2 shadow mb-3">
          <template slot="header">
            <h4 class="card-title text-capitalize">{{ $t('didactics.joined_students') }}</h4>
          </template>
          <el-table width="100" stripe :data="studentsSelected">
            <el-table-column :min-width="200" align="left" :label="$t('datatable.name')">
              <div slot-scope="{ row }">
                <div class="text-truncate small text-uppercase">
                  {{ row |optional('registry.surname') }}
                  {{ row |optional('registry.name') }}
                </div>
                <div class="small text-capitalize">
                  {{ $t('datatable.date_in') }}:
                  <label-theme-component>{{ row.date_in | date }}</label-theme-component>
                </div>
              </div>
            </el-table-column>
            <el-table-column
              :width="120"
              align="center">
              <div slot-scope="{ row }">
                <div class="text-nowrap small">
                  {{ courses[row.course_id] | optional('code') }}
                </div>
              </div>
            </el-table-column>
            <el-table-column
              :width="150"
              align="center"
              :label="$t('datatable.first_contact')">
              <div slot-scope="{ row }">
                <div class="text-truncate small">
                  {{ row.first_contact | date }}
                </div>
              </div>
            </el-table-column>
            <el-table-column
              align="center"
              :width="360"
              :label="$t('datatable.schedule_preferences')">
              <div slot-scope="{ row }">
                <student-schedule-preferences :preferences="row.studentSchedulePreferences"/>
              </div>
            </el-table-column>
            <el-table-column
              :width="160"
              align="center"
              :label="$t('datatable.second_contact')">
              <div slot-scope="{ row }">
                <div class="text-truncate small">
                  {{ row.second_contact | date }}
                </div>
              </div>
            </el-table-column>
            <el-table-column
              :width="200"
              align="center"
              :label="$t('common.accounting_approval')"
              v-if="showAccountingApproval"
            >
              <div slot-scope="{ row }">
                <octo-icon :icon="row.accounting_approval ? 'checked' : 'wrong'"/>
              </div>
            </el-table-column>
            <el-table-column
              :width="200"
              align="center"
              :label="$t('common.enabled_certifications')"
              v-if="showAccountingApproval"
            >
              <div slot-scope="{ row }">
                {{ getEnabledCertificationsCodes(row) }}
              </div>
            </el-table-column>
            <el-table-column :width="100" align="right">
              <div slot-scope="{ row, $index }">
                <base-button icon link class="ml-3 mb-2" @click="toggleStudent(row)">
                  <octo-icon icon="wrong"/>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card body-classes="standard-card-body" class="mr-2 shadow">
          <template slot="header">
            <h4 class="card-title text-capitalize">{{ $t('didactics.available_students') }}</h4>
          </template>
          <div class="text-uppercase small d-flex align-items-center justify-content-center mb-3">
            <el-switch class="mr-2" v-model="showStudentsWithScheduleOnly"/>
            <label-theme-component>{{ $t('didactics.show_students_with_schedule_only') }}</label-theme-component>
          </div>
          <el-table width="100" stripe :data="studentsAvailable">
            <el-table-column
              :min-width="200"
              align="left" :label="$t('datatable.name')">
              <div slot-scope="{ row }">
                <div class="text-truncate small text-uppercase">
                  {{ row |optional('registry.surname') }}
                  {{ row |optional('registry.name') }}
                </div>
                <div class="small text-capitalize">
                  {{ $t('datatable.date_in') }}:
                  <label-theme-component>{{ row.date_in | date }}</label-theme-component>
                </div>
              </div>
            </el-table-column>
            <el-table-column
              :width="120"
              align="center">
              <div slot-scope="{ row }">
                <div class="text-nowrap small">
                  {{ courses[row.course_id] | optional('code') }}
                </div>
              </div>
            </el-table-column>
            <el-table-column
              :width="150"
              align="center"
              :label="$t('datatable.first_contact')">
              <div slot-scope="{ row }">
                <div class="text-truncate small">
                  {{ row.first_contact | date }}
                </div>
              </div>
            </el-table-column>
            <el-table-column
              align="center"
              :width="360"
              :label="$t('datatable.schedule_preferences')">
              <div slot-scope="{ row }">
                <student-schedule-preferences :preferences="row.studentSchedulePreferences"/>
              </div>
            </el-table-column>
            <el-table-column
              :width="160"
              align="center"
              :label="$t('datatable.second_contact')">
              <div slot-scope="{ row }">
                <div class="text-truncate small">
                  {{ row.second_contact | date }}
                </div>
              </div>
            </el-table-column>
            <el-table-column
              :width="200"
              align="center"
              :label="$t('common.accounting_approval')"
              v-if="showAccountingApproval"
            >
              <div slot-scope="{ row }">
                <octo-icon :icon="row.accounting_approval ? 'checked' : 'wrong'"/>
              </div>
            </el-table-column>
            <el-table-column
              :width="200"
              align="center"
              :label="$t('common.enabled_certifications')"
              v-if="showAccountingApproval"
            >
              <div slot-scope="{ row }">
                <div v-if="row.registry && row.registry.enabled_certifications"
                     v-for="(cert, index) in  row.registry.enabled_certifications" v-bind:key="`cert-${index}`">
                  <div class="small">{{ courses[cert.id] | optional('code') }}</div>
                </div>

              </div>
            </el-table-column>
            <el-table-column :width="100" align="right">
              <div slot-scope="{ row, $index }">
                <base-button icon link class="ml-3 mb-2" @click="toggleStudent(row)">
                  <octo-icon icon="add"/>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {Table, TableColumn, Switch} from "element-ui";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import StudentSchedulePreferences from "@/pages/Students/components/StudentSchedulePreferences";
import {mapGetters, mapMutations} from "vuex";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {endpoints} from "@/routes/endpoints";
import classroomTypes from "@/pages/Didactics/resources/classroomTypes";

export default {
  name: "ClassroomOnGoingStudents",
  components: {
    OctoHeaderButton,
    LabelThemeComponent,
    StudentSchedulePreferences,
    ListSelectorComponent,
    ListGroupItemComponent,
    OctoIcon,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Switch.name]: Switch,
  },
  data() {
    return {
      headerButtons: [
        {
          label: 'save_students',
          onClick: 'onSaveStudents'
        },
      ],
      studentsLocal: [],
      studentsAvailableLocal: [],
      studentsSelectedLocal: [],
      showStudentsWithScheduleOnly: false
    }
  },
  props: {},
  watch: {
    classroom: {
      handler(val) {
        const students = [];

        this.$_.each(this.$_.cloneDeep(this.classroom.students), student => {
          student.selected = true;
          students.push(student);
        });

        this.$_.each(this.$_.cloneDeep(this.classroom.availableStudents), student => {
          student.selected = false;
          students.push(student);
        });

        this.studentsLocal = this.$_.sortBy(students, student => {
          return student?.registry?.surname + ' ' + student?.registry?.name
        });
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing',
      courses: 'common/allCourses'
    }),

    showAccountingApproval() {
      return this.$_.includes(
        [classroomTypes.certificate.value, classroomTypes.workshop.value],
        this.classroom?.courses[0]?.type
      );
    },

    studentsSelected() {
      return this.$_.filter(this.studentsLocal, student => student.selected)
    },

    studentsAvailable() {
      return this.$_.filter(this.studentsLocal, student => {
        return !student.selected && (student?.studentSchedulePreferences?.length || !this.showStudentsWithScheduleOnly);
      })
    },
  },
  methods: {
    ...mapMutations({
      setPartialClassroomOnGoing: 'current/setPartialClassroomOnGoing'
    }),

    toggleStudent(student) {
      this.$set(
        this.studentsLocal[this.$_.findIndex(this.studentsLocal, studentLocal => studentLocal.id === student.id)],
        'selected',
        !student.selected
      );
    },

    saveStudents: function () {
      this.$fullLoading.show();
      this.$api.put(
        endpoints.CLASSROOM_UPDATE_ON_GOING_CERTIFICATE_STUDENTS.replace('{id}', this.classroom.id),
        {student_ids: this.$_.map(this.studentsSelected, (item) => item.id)}
      )
        .then((resp) => {
          this.setPartialClassroomOnGoing({key: 'students', value: resp?.data?.data?.students});
          this.setPartialClassroomOnGoing({key: 'lessons', value: resp?.data?.data?.lessons});
          this.setPartialClassroomOnGoing({key: 'availableStudents', value: resp?.data?.meta?.availableStudents});
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    getEnabledCertificationsCodes(student) {
      return this.$_.map(student?.registry?.enabled_certifications, item => this.courses[item.id].code).join(', ');
    },
  }
}
</script>

<style scoped>

</style>
