<template>
  <div class="row">
    <div class="col-12">
      <card class="shadow" body-classes="standard-card-body">
        <template slot="header">
          <h4 class="card-title text-capitalize">{{ $t('didactics.students') }}</h4>
        </template>
        <el-table stripe :data="students">
          <el-table-column align="left" min-width="250" :label="$t('didactics.student')">
            <div slot-scope="{ row }">
              <div class="text-uppercase">
                {{ row | optional('registry.surname') }}
                {{ row | optional('registry.name') }}
              </div>
            </div>
          </el-table-column>
          <el-table-column align="left" min-width="250" :label="$t('common.certificate')">
            <div slot-scope="{ row }">
              <el-tooltip :content="courses[row.course_id] | optional('title')" :open-delay="300" placement="top">
                <label-theme-component>{{ courses[row.course_id] | optional('code') }}</label-theme-component>
              </el-tooltip>
            </div>
          </el-table-column>
          <el-table-column align="center" width="100" :label="$t('didactics.present')">
            <div slot-scope="{ row }">
              <octo-icon :icon="row.lessonPresence ? 'checked' : 'wrong'"/>
            </div>
          </el-table-column>
          <el-table-column align="center" width="100" :label="$t('didactics.promoted')">
            <div slot-scope="{ row }">
              <octo-icon :icon="row.promoted ? 'checked' : 'wrong'"/>
            </div>
          </el-table-column>
          <template slot="empty">
            {{ $t('datatable.no_result') }}
          </template>
        </el-table>
      </card>
    </div>
  </div>
</template>

<script>

import LabelThemeComponent from "@/components/LabelThemeComponent";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {mapGetters} from "vuex";
import {Table, TableColumn} from "element-ui";

export default {
  name: "ClassroomOnGoingStudentsByCertificateRecap",
  components: {
    OctoIcon,
    LabelThemeComponent,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      students: [],
    }
  },
  watch: {
    classroom: {
      handler(val) {
        const lessonStudents = this.classroom?.lessons?.[0]?.students || [];

        const students = this.$_.map(
          this.$_.cloneDeep(this.classroom.students),
          student => {
            student.lessonPresence = !!this.$_.find(lessonStudents, item => item.id === student.id)?.lessonPresence;
            return student;
          }
        );

        this.students = this.$_.sortBy(students, student => student?.registry?.surname + ' ' + student?.registry?.name);
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing',
      courses: 'common/allCourses',
    }),
  }
}
</script>

<style scoped>

</style>
