<template>
  <div class="row">
    <div class="col-12">
      <card class="shadow" body-classes="standard-card-body pt-0">
        <template slot="header">
          <h4 class="card-title text-capitalize">{{ $t('fields.certificates') }}</h4>
        </template>
        <ul class="list-group list-group-flush">
          <list-group-item-component
            v-for="(certificate, index) in certificates"
            v-bind:key="`certificate-1-${index}`"
          >
            <template slot="label">
              <div class="standard-label text-truncate">
                <label-theme-component>{{ certificate.code + ': ' }}</label-theme-component>
                {{ certificate.title }}
              </div>
            </template>
            <template slot="value">
              <report-value-container :data="certificate.students"/>
            </template>
          </list-group-item-component>
        </ul>
      </card>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import ReportValueContainer from "@/pages/Reports/components/ReportValueContainer";

export default {
  name: "ClassroomOnGoingCertificatesRecap",
  components: {
    ReportValueContainer,
    LabelThemeComponent,
    ListGroupItemComponent,
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing',
    }),

    certificates() {

      const mappedStudents = this.$_.map(this.classroom.students, student => {
        return {
          id: student.id,
          course_id: student.course_id,
          name: student?.registry?.surname + ' ' + student?.registry?.name
        }
      })

      return this.$_.map(this.$_.cloneDeep(this.classroom.courses), certificate => {
        certificate.students = this.$_.filter(
          this.$_.cloneDeep(mappedStudents),
          student => student.course_id === certificate.id
        );

        return certificate;
      });
    }
  }
}
</script>

<style scoped>

</style>
