<template>
  <div class="row">
    <div class="col-12">
      <octo-header-button
        :buttons="headerButtons"
        :title="classroom.code"
        @onUpdateCertificatePresences="updateCertificatePresences"
      />
    </div>
    <div class="col-12">
      <card class="shadow" body-classes="standard-card-body">
        <el-table stripe :data="students">
          <el-table-column align="left" min-width="250" :label="$t('didactics.student')">
            <div slot-scope="{ row }">
              <div class="text-uppercase">
                {{ row | optional('registry.surname') }}
                {{ row | optional('registry.name') }}
              </div>
            </div>
          </el-table-column>
          <el-table-column align="left" min-width="250" :label="$t('common.certificate')">
            <div slot-scope="{ row }">
              <el-tooltip :content="courses[row.course_id] | optional('title')" :open-delay="300" placement="top">
                <label-theme-component>{{ courses[row.course_id] | optional('code') }}</label-theme-component>
              </el-tooltip>
            </div>
          </el-table-column>
          <el-table-column align="center" width="100" :label="$t('didactics.present')">
            <div slot-scope="{ row }">
              <el-switch v-model="row.lessonPresence" @change="togglePromoted(row)"/>
            </div>
          </el-table-column>
          <el-table-column align="center" width="100" :label="$t('didactics.promoted')">
            <div slot-scope="{ row }">
              <el-switch v-model="row.promoted" :disabled="!row.lessonPresence"/>
            </div>
          </el-table-column>
          <template slot="empty">
            {{ $t('datatable.no_result') }}
          </template>
        </el-table>
<!--        <ul-->
<!--          class="list-group list-group-flush"-->
<!--          v-for="certificate in certificates"-->
<!--          v-bind:key="`certificate-${certificate.id}`"-->
<!--        >-->
<!--          <list-group-title-section-component :label="certificate.code + ': ' + certificate.title"/>-->
<!--          <li class="standard-li">-->
<!--            <el-table stripe :data="certificate.students">-->
<!--              <el-table-column align="left" min-width="250" :label="$t('didactics.student')">-->
<!--                <div slot-scope="{ row }">-->
<!--                  <div class="text-uppercase">-->
<!--                    {{ row | optional('registry.surname') }}-->
<!--                    {{ row | optional('registry.name') }}-->
<!--                  </div>-->
<!--                </div>-->
<!--              </el-table-column>-->
<!--              <el-table-column align="center" width="100" :label="$t('didactics.present')">-->
<!--                <div slot-scope="{ row }">-->
<!--                  <el-switch v-model="row.lessonPresence" @change="togglePromoted(row)"/>-->
<!--                </div>-->
<!--              </el-table-column>-->
<!--              <el-table-column align="center" width="100" :label="$t('didactics.promoted')">-->
<!--                <div slot-scope="{ row }">-->
<!--                  <el-switch v-model="row.promoted" :disabled="!row.lessonPresence"/>-->
<!--                </div>-->
<!--              </el-table-column>-->
<!--              <template slot="empty">-->
<!--                {{ $t('datatable.no_result') }}-->
<!--              </template>-->
<!--            </el-table>-->
<!--          </li>-->
<!--        </ul>-->
      </card>
    </div>
  </div>
</template>

<script>

import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {mapGetters, mapMutations} from "vuex";
import {Switch, Table, TableColumn} from "element-ui";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {endpoints} from "@/routes/endpoints";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";

export default {
  name: "ClassroomOnGoingStudentsByCertificatePresences",
  components: {
    ListGroupTitleSectionComponent,
    OctoHeaderButton,
    LabelThemeComponent,
    ListGroupItemComponent,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Switch.name]: Switch,
  },
  data() {
    return {
      certificates: [],
      students: [],
      headerButtons: [
        {
          label: 'save_certificate_result',
          onClick: 'onUpdateCertificatePresences'
        }
      ]
    }
  },
  watch: {
    classroom: {
      handler(val) {
        const lessonStudents = this.classroom?.lessons?.[0]?.students || [];

        const students = this.$_.map(
          this.$_.cloneDeep(this.classroom.students),
          student => {
            student.lessonPresence = !!this.$_.find(lessonStudents, item => item.id === student.id)?.lessonPresence;
            return student;
          }
        );

        this.students = this.$_.sortBy(students, student => student?.registry?.surname + ' ' + student?.registry?.name);
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing',
      courses: 'common/allCourses',
    }),
  },
  methods: {
    ...mapMutations({
      setPartialClassroomOnGoing: "current/setPartialClassroomOnGoing"
    }),

    togglePromoted(student) {
      if (!student.presence) {
        student.promoted = false;
      }
    },

    updateCertificatePresences() {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.CLASSROOM_UPDATE_ON_GOING_CERTIFICATE_PRESENCES.replace('{id}', this.classroom.id),
        {
          students: this.$_.map(this.students, student => {
            return {
              id: student.id,
              presence: !!student.lessonPresence,
              promoted: !!student.promoted,
            }
          })
        }
      )
      .then((resp) => {
        this.setPartialClassroomOnGoing({key: 'lessons', value: resp?.data?.data?.lessons});
        this.setPartialClassroomOnGoing({key: 'students', value: resp?.data?.data?.students});
      })
      .catch(() => {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      })
      .finally(() => {
        this.$fullLoading.hide();
      })
    }
  }
}
</script>

<style scoped>

</style>
